import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchProgress = createAsyncThunk('progressSlice/fetchProgress', async (_, thunkApi) => {
  return fetchData(api.urlProgress, thunkApi);
});

export const fetchProgressCompareSlider = createAsyncThunk(
  'progressSlice/fetchProgressCompareSlider',
  async (_, thunkApi) => {
    return fetchData(api.urlProgressCompareSlider, thunkApi);
  },
);

const initialState = {
  progress: null,
  stageOne: [],
  stageTwo: [],
  stageThree: [],
  activeStage: 0,
  tabs: [
    { text: 'Все', stage: 0 },
    { text: '1 очередь', stage: 1 },
    { text: '2 очередь', stage: 2 },
    { text: '3 очередь', stage: 3 },
  ],
  compareSlider: [],
  fetchError: false,
};

export const progressSlice = createSlice({
  name: 'progressSlice',
  initialState,
  reducers: {
    setActiveStage: (state, action) => {
      state.activeStage = action.payload;
    },
  },
  extraReducers: {
    [fetchProgress.fulfilled]: (state, action) => {
      state.progress = action.payload;
      action.payload.forEach(item => {
        if (item.number_stage === 1) {
          state.stageOne.push(item);
        } else if (item.number_stage === 2) {
          state.stageTwo.push(item);
        } else if (item.number_stage === 3) {
          state.stageThree.push(item);
        }
      });
    },
    [fetchProgress.rejected]: state => {
      state.fetchError = true;
    },
    [fetchProgressCompareSlider.fulfilled]: (state, action) => {
      if (action?.payload?.items?.length !== 0 && !action?.payload?.hidden) {
        state.compareSlider = action?.payload?.items;
      }
    },
    [fetchProgressCompareSlider.rejected]: state => {
      state.fetchError = true;
    },
  },
});

export const { setActiveStage } = progressSlice.actions;

export default progressSlice.reducer;
