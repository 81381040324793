import { PDF_GEN, UNIVERSAL_ADMIN } from '../tools/helpers/constants';

const api = {
  urlFlats: process.env.REACT_APP_MANAGERFORMA_API_FUll,
  urlAttributes: `${process.env.REACT_APP_MANAGER_URL}/api/lot-attributes/list`,
  urlBulks: `${process.env.REACT_APP_MANAGER_URL}/api/bulks?blockId=1457`,
  urlPdf: PDF_GEN,

  //Страница "Условия покупки", относительные пути апи работают через прокси (setupProxy.js)

  urlNavList: `${UNIVERSAL_ADMIN}/menu/purchase`,
  urlTradeIn: `${UNIVERSAL_ADMIN}/special_program/trade-in`,
  urlQa: `${UNIVERSAL_ADMIN}/qa`,
  urlSpecialMortgages: `${UNIVERSAL_ADMIN}/special_program/mortgage`,
  urlBuyout: `${UNIVERSAL_ADMIN}/special_program/buyout`,
  urlMortgages: `${UNIVERSAL_ADMIN}/special_program/mortgage-standard`,
  urlCalcData: `${UNIVERSAL_ADMIN}/mortgages`,
  urlInstallments: `${UNIVERSAL_ADMIN}/special_program/installment`,
  urlSpecialInstallments: `${UNIVERSAL_ADMIN}/special_program/installment-main`,
  urlCash: `${UNIVERSAL_ADMIN}/special_program/cash`,
  urlCashback: `${UNIVERSAL_ADMIN}/special_program/cashback`,
  urlPromoCards: `${UNIVERSAL_ADMIN}/promo_cards`,

  urlGeneralSettings: `${UNIVERSAL_ADMIN}/general_settings`,

  // Страница "Документы"
  urlDocuments: `${UNIVERSAL_ADMIN.slice(0, -5)}/v2/1457/documents`,

  // Страница "Школа"
  urlSchool: `${UNIVERSAL_ADMIN}/single-page/school`,

  // Страница 'Политика конфиденциальности'
  urlPolicy: `${UNIVERSAL_ADMIN}/single-page/policy`,

  // Страница "Новости"
  urlNews: `${UNIVERSAL_ADMIN.slice(0, -5)}/v2/1457/news`,
  // Слайдер на странцие конкретной "Новости"
  urlNewsSlider: `${UNIVERSAL_ADMIN.slice(0, -5)}/v2/1457/slider`,

  // Страница "Ход строительства"
  urlProgress: `${UNIVERSAL_ADMIN}/progress`,
  urlProgressCompareSlider: `${UNIVERSAL_ADMIN.slice(0, -5)}/v2/1457/promo_cards/now_future`,

  // Страница 'Архитектура'
  urlArchitecture: `${UNIVERSAL_ADMIN}/slider/architecture`,

  // Страница 'Галерея'
  urlGallery: `${UNIVERSAL_ADMIN}/gallery`,

  //Меню
  urlMenuLeft: `${UNIVERSAL_ADMIN}/menu/burger-left`,
  urlMenuRight: `${UNIVERSAL_ADMIN}/menu/burger-right`,
  urlFooterLeft: `${UNIVERSAL_ADMIN}/menu/footer-left`,
  urlFooterRight: `${UNIVERSAL_ADMIN}/menu/footer-right`,
  urlFooterMiddle: `${UNIVERSAL_ADMIN}/menu/footer-middle`,
  urlDvizh: 'https://gql.make.dvizh.io/gql',
  urlDvizhSwitcher: `${UNIVERSAL_ADMIN}/manual_switches/dvizh`,
};

export default api;
